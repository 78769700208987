import React from "react";
import MainLayout from "../../layouts/MainLayout";
import CheckOut from "../../components/customer/CheckOut";

const CheckOutView = () => {
  return (
    <MainLayout>
      <CheckOut />
    </MainLayout>
  );
};

export default CheckOutView;
