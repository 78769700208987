import React from "react";
import MainLayout from "../../layouts/MainLayout";
import CategoryProduct2 from "../../components/customer/CategoryProduct2";

const CategoryMemberView = () => {
  return (
    <MainLayout>
      <CategoryProduct2 />
    </MainLayout>
  );
};

export default CategoryMemberView;
