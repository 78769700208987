import React from "react";
import MainLayout from "../layouts/MainLayout";
import Product2 from "../components/customer/Product2";

const ViewProduct = () => {
  return (
    <MainLayout>
      <Product2 />
    </MainLayout>
  );
};

export default ViewProduct;
