import React from "react";
import MainLayout from "../../layouts/MainLayout";
import DetailProductComponent from "../../components/customer/DetailProductComponent";

const DetailProduct = () => {
  return (
    <MainLayout>
      <DetailProductComponent />
    </MainLayout>
  );
};

export default DetailProduct;
