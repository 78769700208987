import React from "react";
import MainLayout from "../../layouts/MainLayout";
import SettingUser from "../../components/customer/SettingUser";

const UserSettingView = () => {
  return (
    <MainLayout>
      <SettingUser />
    </MainLayout>
  );
};

export default UserSettingView;
