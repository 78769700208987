import React from "react";
import promo from "../../assets/promo1.jpeg";
import promo2 from "../../assets/promo2.jpeg";
import promo3 from "../../assets/promo3.jpeg";

export default [
  {
    title: " First promo",
    description: "ajhsgdhsgajdhsj",
    urls: promo,
  },
  {
    title: " Second promo",
    description: "ajhsgdhsgajdhsj",
    urls: promo2,
  },
  {
    title: " Third promo",
    description: "ajhsgdhsgajdhsj",
    urls: promo3,
  },
];
