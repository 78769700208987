import React from "react";
import MainLayout from "../../layouts/MainLayout";
import DetailBundle from "../../components/customer/DetailBundle";

const DetailBundleView = () => {
  return (
    <MainLayout>
      <DetailBundle />
    </MainLayout>
  );
};

export default DetailBundleView;
